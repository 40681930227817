// 用来管理菜单相关的数据
export default {
  state:{
    isCollapse: false, //用于控制菜单展开还是收起
    tabsList:[   //面包屑的数据
      {
        path: '/',
        name: 'home',
        label: '首页',
        icon: 's-home',
        url: 'Home/Home'
      },
    ]
  },
  mutations:{
    // 修改菜单的方法
    collapseMenu(state){
      state.isCollapse = !state.isCollapse
    },
    // 更新面包屑数据
    selectMenu(state,item){
      // 判断添加的数据是否为首页 下面：不是首页
      if(item.name !== 'home'){
          const index = state.tabsList.findIndex(val => val.name === item.name)
          // 且请求的数据不存在，
          if(index===-1){
            state.tabsList.push(item)
          }
      }
    },
    // 关闭tags标签，删除state的数据
    closeTag(state,item){
      const index = state.tabsList.findIndex(val => val.name === item.name)
      state.tabsList.splice(index,1)
    }
  },
}