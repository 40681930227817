// 对外暴露 存储token
export const setToken = (name, articleid, level) => {
  sessionStorage.setItem("name", name);
  sessionStorage.setItem("articleid", articleid);
  sessionStorage.setItem("level", level);
};

// 获取token
export const getToken = () => {
  return sessionStorage.getItem("name", "articleid", "level");
};

// 删除token
export const removeToken = () => {
  return sessionStorage.removeItem("name", "articleid", "level");
};
