import { getMsgList } from "@/api";
// 建立article仓库的数据
const state = {
  msgList: {},
};
const mutations = {
  GETMSGLIST(state, msgList) {
    state.msgList = msgList;
  },
};
const actions = {
  // 获取用户留言列表
  async getMsgList({ commit }) {
    let result = await getMsgList();
    // if (result.code == 200) {
    commit("GETMSGLIST", result.data);
    // }
  },
  //   删除用户留言
};
export default {
  state,
  mutations,
  actions,
};
