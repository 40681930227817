import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Home from "@/views/Home.vue";
import User from "@/views/User.vue";
import Main from "@/views/Main.vue";
import Article from "@/views/EditArticle/Article";
import PageOne from "@/views/PageOne";
import PageTwo from "@/views/PageTwo";
import PageThree from "@/views/PageThree";
import Center from "@/views/Center";

// 登录校验页,进行前置守卫的判断
import Login from "@/views/Login.vue";

// 导入store
import store from "@/store";

// 文章编辑页
import editPage from "@/views/EditArticle/editPage";
import editPage2 from "@/views/EditArticle/editPageTwo";
import editPage3 from "@/views/EditArticle/editPageThree";
import lookPage from "@/views/EditArticle/lookPage";
let originPush = VueRouter.prototype.push;
// 重写push与replace
// 第一个参数：告诉原来的方法，往哪里跳转（传递哪些参数）
// 第二个，成功的回调，第三个失败的回调
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    // .call()调用函数一次，篡改函数的上下文一次
    // call方法使用一个指定的 this 值和单独给出的一个或多个参数来调用一个函数
    // 要保证重写的上下文是VueRouter的实例才不影响原本的push执行效果
    // call之前，originPush的this是window。通过call把originPush的this改成了VueRouter的this
    // 通过这个操作，给push增加了回调，解决了promise报错的问题
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
// 重写replace
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

// 1.创建路由组件
// 2.定义路由 让路由与组件进行映射
const routes = [
  // 主路由
  {
    path: "/",
    component: Main,
    redirect: "/login",
    children: [
      { path: "home", name: "home", component: Home },
      { path: "user", name: "user", component: User },
      { path: "Article", name: "Article", component: Article },
      { path: "page1", name: "page1", component: PageOne },
      { path: "page2", name: "page2", component: PageTwo },
      { path: "page3", name: "page3", component: PageThree },
      { path: "center", name: "center", component: Center },
    ],
  },
  // 文章编辑页与预览页
  {
    path: "/look",
    component: lookPage,
  },
  {
    path: "/edit",
    component: editPage,
  },
  {
    path: "/edittwo",
    component: editPage2,
  },
  {
    path: "/editthree",
    component: editPage3,
  },
  // 匹配路由校验页规则
  {
    path: "/login",
    component: Login,
  },
  // 重定向到登录页
  // { path: "/", redirect: "/login" },
];
// 3.创建router实例
const router = new VueRouter({
  routes,
});

// 4.对外暴露
export default router;
