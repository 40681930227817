import Vue from "vue";
import vuex from "vuex";
import tab from "./tab";
import user from "./user";
import article from "./article";
import login from "./login";

Vue.use(vuex);

// 创建vuex的实例
export default new vuex.Store({
  modules: {
    tab,
    user,
    article,
    login,
  },
});
