<template>
  <div>
    <div class="content">
      <div class="article">
        <p v-html="this.article.article.value"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lookPage",
  data() {
    return {
      article: "",
    };
  },

  //   在页面初创阶段直接去发起请求 查询文章
  created() {
    this.article = this.$route.query;
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 10vh;
  margin-left: 20vw;
  width: 60vw;
  height: 100%;
  .article {
    margin-top: 16vh;
    font-weight: 400;
  }
}
</style>
