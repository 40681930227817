import { backLogin } from "@/api";
import { setToken, getToken, removeToken } from "@/utils/token";

// 建立article仓库的数据
const state = {
  userLevel: {},
};
const mutations = {
  GETARTICLELIST(state, userinfo) {
    state.userLevel = userinfo;
  },
};
const actions = {
  // 登录 获取用户等级信息
  async getUserLevel({ commit }, data) {
    let result = await backLogin(data);

    if (result.status == 200) {
      commit("GETARTICLELIST", result.data.data[0]);
      //   console.log(result.data.data[0].name);
      //   console.log(result.data.data[0].articleid);
      //   console.log(result.data.data[0].level);
      setToken(
        result.data.data[0].name,
        result.data.data[0].articleid,
        result.data.data[0].level
      );
      return "ok";
    } else {
      return Promise.reject(new Error("fail"));
    }
  },
};
export default {
  state,
  mutations,
  actions,
};
