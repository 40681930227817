import { getArticleList } from "@/api";
// 建立article仓库的数据
const state = {
  articleList: {},
};
const mutations = {
  GETARTICLELIST(state, articleList) {
    state.articleList = articleList;
  },
};
const actions = {
  async getArticleList({ commit }) {
    let result = await getArticleList();
    commit("GETARTICLELIST", result.data);
  },
};
export default {
  state,
  mutations,
  actions,
};
