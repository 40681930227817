import Mock from "mockjs";
import homeApi from "./mockData/home.js";
import userApi from "./mockData/user.js";

// 定义mock请求拦截
Mock.mock("/api/home/getData", homeApi.getStatisticalData);

// 用户列表的数据;
Mock.mock("/api/user/add", "post", userApi.createUser);
Mock.mock("/api/user/edit", "post", userApi.updateUser);
Mock.mock("/api/user/delete", "post", userApi.deleteUser);
Mock.mock("/api/user/getUser", userApi.getUserList);
